import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LibrariesComponent } from './components/libraries/libraries.component';
import { AdminLogsComponent } from './admin-logs/admin-logs.component';



const routes: Routes = [

  // Redirect any unknown routes to the home page
  {
    path: '', loadChildren: () =>
      import('./chat/chat.module').then((m) => m.ChatModule),
  },
  {
    path: 'libs',
    component: LibrariesComponent,
  },
  {
    path: 'admin-logs',
component:AdminLogsComponent  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
