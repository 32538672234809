import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-logs',
  template: `
    <div class="admin-logs">
      <h2>Admin Logs</h2>
      <!-- Add your admin logs content here -->
    </div>
  `,
  styles: [`
    .admin-logs {
      padding: 20px;
    }
  `]
})
export class AdminLogsComponent {
  constructor() { }
}
